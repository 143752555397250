<template>
  <div v-if="practice">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: isAdmin ? 'SatManagement' : 'TeacherSatManagement',
              query: { type: isDrill ? 'drill' : 'practice' }
            }"
          >
            View
          </router-link>
        </el-breadcrumb-item>
        <!-- <el-breadcrumb-item v-if="composePractice">
          <router-link
            :to="{
              name: 'SatComposePracticeDetail',
              query: { id: composePractice.id }
            }"
          >
            {{ composePractice.exam.title }}
          </router-link>
        </el-breadcrumb-item> -->
        <el-breadcrumb-item v-if="practice">
          {{ `${practice.exam.title}` }}
          （<b v-if="practice.exam.is_public === 1">Published</b>
          <b v-else>Drafts</b>）
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="text-right">
      <div class="title-box-button">
        <template v-if="isEdit">
          <template v-if="this.id && Number(this.id) > 0">
            <el-tooltip
              class="item ml-2"
              effect="dark"
              content="Cancel"
              placement="top"
            >
              <router-link
                :to="{
                  name: $route.name,
                  params: {
                    ...$route.params
                  },
                  query: {
                    ...$route.query,
                    isEdit: 0
                  }
                }"
              >
                <el-button type="primary" size="small">
                  Save
                  <i class="fas fa-save"></i>
                </el-button>
              </router-link>
            </el-tooltip>
          </template>
        </template>
        <template v-else>
          <el-tooltip
            class="item ml-2"
            effect="dark"
            content="Edit"
            placement="top"
          >
            <router-link
              :to="{
                name: $route.name,
                params: {
                  ...$route.params
                },
                query: {
                  ...$route.query,
                  isEdit: 1
                }
              }"
            >
              <el-button type="warning" size="small">
                Edit
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
          <template>
            <el-tooltip
              class="ml-2"
              effect="dark"
              content="Change to draft"
              placement="top"
              v-if="practice.exam.status && practice.exam.status !== 'DRAFT'"
            >
              <el-button
                type="danger"
                size="small"
                @click="updateStatus(practice.exam.id, 'DRAFT')"
              >
                Change to draft
                <i class="fas fa-level-down-alt"></i>
              </el-button>
            </el-tooltip>
            <el-popover
              placement="top"
              width="250"
              trigger="hover"
              v-if="practice.exam.status && practice.exam.status === 'DRAFT'"
            >
              <div class="text-center">
                <el-button
                  type="success"
                  size="mini"
                  @click="updateStatus(practice.exam.id, 'PUBLIC')"
                >
                  Public
                  <i class="fas fa-users"></i>
                </el-button>
                <el-button
                  type="success"
                  size="mini"
                  @click="updateStatus(practice.exam.id, 'PRIVATE')"
                >
                  Private
                  <i class="fas fa-user-lock"></i>
                </el-button>
              </div>
              <el-button
                type="success"
                size="small"
                class="ml-2"
                slot="reference"
              >
                Publish
                <i class="fas fa-upload"></i>
              </el-button>
            </el-popover>
          </template>
        </template>
      </div>
    </div>
    <hr />
    <div ref="ivy-question">
      <el-form ref="form" :model="practice" label-width="180px">
        <el-form-item label="Name" required>
          <el-input
            v-show="isEdit"
            v-model="practice.exam.title"
            maxlength="100"
            show-word-limit
            @change="updateInfo({ title: practice.exam.title })"
          ></el-input>
          <b v-show="!isEdit">
            {{ practice.exam.title }}
          </b>
        </el-form-item>
        <template v-if="isAdmin && false">
          <!-- <el-form-item label="Type" >
            <el-radio-group
              v-show="isEdit"
              v-model="isDrill"
              :disabled="!isEdit"
              @change="updateDrill(isDrill)"
            >
              <el-radio-button label="default">
                {{ $t(`sat.Partial Tests`) }}
              </el-radio-button>
              <el-radio-button label="drill">
                {{ $t(`sat.Drill Practice`) }}
              </el-radio-button>
            </el-radio-group>
            <div v-show="!isEdit">
              <b v-if="isDrill === 'default'"> {{ $t(`sat.Partial Tests`) }}</b>
              <b v-if="isDrill === 'drill'"> {{ $t(`sat.Drill Practice`) }}</b>
            </div>
            <el-form-item label="Version" prop="isNew">
              <el-radio-group
                v-model="practice.exam.is_new"
                @change="updateSubject(practice.category)"
              >
                <el-radio-button :label="1">
                  New Digital SAT
                </el-radio-button>
                <el-radio-button :label="0">
                  Old Paper-Based SAT
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form-item> -->
          <el-form-item label="Subjects" v-show="isEdit">
            <el-radio-group
              v-model="practice.exam.category"
              :disabled="!isEdit"
              @change="updateSubject(practice.exam.category)"
            >
              <el-radio-button
                v-for="subject in subjectsNow"
                :key="subject.id"
                :label="subject.id"
              >
                {{ titleCase(subject.name) }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </template>
        <el-form-item>
          <div slot="label">
            Question(s)
          </div>
          <div>
            <el-alert type="success" :closable="false" class="mb-3">
              <h6 class="m-2">
                Questions:
                {{ practice.exam.exam_questions.length }}
              </h6>
            </el-alert>
          </div>
          <div class="mb-4" v-if="isEdit && !hasTaken">
            <el-button
              type="success"
              style="width: 100%;"
              @click="showCreateNewQuestionDialog = true"
            >
              Add <i class="fa fa-plus"></i>
            </el-button>
          </div>
          <el-alert
            v-if="hasTaken && isEdit"
            class="mb-4 p-3"
            title="This Test has been tested by students. Only basic information can be edited, and the number and order of units cannot be changed."
            type="warning"
            show-icon
            :closable="false"
          >
          </el-alert>
          <el-alert
            v-if="!hasTaken && isEdit"
            class="mb-4 p-3"
            title="Drag and drop questions to sort them."
            type="info"
            show-icon
            :closable="false"
          >
          </el-alert>

          <div v-if="practice.exam.exam_questions.length > 0 && isEdit">
            <el-collapse accordion>
              <draggable
                :list="practice.exam.exam_questions"
                :class="!isEdit || hasTaken ? '' : 'question-card'"
                :disabled="!isEdit || hasTaken"
                @change="setQuestionOrder"
              >
                <el-collapse-item
                  v-for="(question, index) in practice.exam.exam_questions"
                  :key="question.id"
                >
                  <template slot="title">
                    <div class="show-question">
                      <div class="show-question-kbd">
                        <kbd>{{ index + 1 }}</kbd>
                      </div>
                      <div class="show-question-passage">
                        <h6
                          v-if="
                            question.question.sat_passage &&
                              question.question.sat_passage.content
                          "
                          class="ml-2 show-passage"
                          v-html="question.question.sat_passage.content"
                        ></h6>
                        <h6
                          class="m-0 ml-2 quick-view-question"
                          v-html="question.question.content"
                        ></h6>
                      </div>
                      <div class="buttons text-right" v-show="isEdit">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Preview"
                          placement="top"
                        >
                          <router-link
                            :to="{
                              name: 'QuestionPreview',
                              params: {
                                questionId: question.question_id
                              }
                            }"
                          >
                            <el-button type="success" size="mini">
                              <i class="fas fa-eye"></i>
                            </el-button>
                          </router-link>
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Edit"
                          placement="top"
                          v-if="isAdmin"
                        >
                          <router-link
                            :to="{
                              name: 'SatEditQuestion',
                              query: {
                                questionId: question.question.id
                              }
                            }"
                          >
                            <el-button type="warning" size="mini">
                              <i class="fas fa-edit"></i>
                            </el-button>
                          </router-link>
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Remove"
                          placement="top"
                          v-show="isEdit && !hasTaken"
                        >
                          <el-button
                            type="danger"
                            size="mini"
                            @click.stop="removeQuestion(question.id)"
                          >
                            <i class="fas fa-times-circle"></i>
                          </el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </template>
                  <div class="options-box mb-2">
                    <ul
                      class="ivy-question-options"
                      v-if="question.question.type === 'default'"
                    >
                      <li
                        class="option"
                        v-for="(option, index) in question.question.sat_options"
                        :key="index"
                      >
                        <div style="width:1.5rem">
                          <span class="text-success">
                            <i
                              v-if="
                                isAnswer(
                                  question.question.sat_answers,
                                  option.letter
                                )
                              "
                              class="fas fa-check-circle"
                            ></i>
                          </span>
                        </div>
                        <span style="width:1.3rem">{{
                          `${option.letter}) `
                        }}</span>
                        <div class="optionText" v-html="option.title"></div>
                      </li>
                    </ul>
                    <template v-else>
                      <h6>
                        <b>Answer:</b>
                        <span
                          class="ml-1"
                          v-for="(answer, index) in question.question
                            .sat_answers"
                          :key="index"
                        >
                          {{ answer.answers[0] }}
                          <span
                            v-if="
                              index < question.question.sat_answers.length - 1
                            "
                          >
                            ,
                          </span>
                        </span>
                      </h6>
                    </template>
                    <div
                      v-if="
                        (question.question.sat_question_tags || []).length > 0
                      "
                      class="mb-2 mt-2"
                    >
                      <b>
                        Topics:
                        <el-tag
                          v-for="tag in question.question.sat_question_tags"
                          :key="tag.id"
                          type="success"
                          size="small"
                          class="ivy-tag"
                        >
                          {{ `[${tag.type}] - ${tag.name}` }}
                        </el-tag>
                      </b>
                    </div>
                    <div
                      v-if="
                        question.question.explanation &&
                          question.question.explanation !== ''
                      "
                      class="mb-2 mt-2"
                    >
                      <b class="mr-2">Explanation:</b>
                      <span v-html="question.question.explanation"></span>
                    </div>
                  </div>
                </el-collapse-item>
              </draggable>
            </el-collapse>
          </div>
          <div v-else>
            <QuestionsTable
              :showQuestion="true"
              :showPassage="true"
              :showOptions="true"
              :showAnswer="true"
              :showExplanation="false"
              :showLabels="false"
              :questions="showQuestions"
              :search="$route.query.search"
              :isAdmin="isAdmin"
            >
            </QuestionsTable>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      @close="reload"
      class="add-passage-dialog"
      :visible.sync="showCreateNewQuestionDialog"
      :close-on-click-modal="false"
      width="90%"
      top="2vh"
    >
      <div slot="title">
        Add question: <kbd>{{ practice.exam.exam_questions.length + 1 }}</kbd>
      </div>
      <el-form ref="form" label-width="180px">
        <el-form-item :label="$t('AddSubject.Method')">
          <el-radio-group v-model="addMode">
            <el-radio-button :label="1">
              Choose from Existing
            </el-radio-button>
            <el-radio-button :label="0">
              Create a New Question
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="content-box" v-if="addMode === 0">
        <el-form ref="form" label-width="180px">
          <el-form-item
            label="Subjects"
            v-if="this.practice.exam.category > 10"
          >
            <el-radio-group v-model="this.practice.subject_id">
              <el-radio-button :label="5">
                English
              </el-radio-button>
              <el-radio-button :label="3">
                Math
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Subjects" v-else>
            <el-radio-group v-model="this.practice.subject_id">
              <el-radio-button :label="1">
                Reading
              </el-radio-button>
              <el-radio-button :label="2">
                Writing
              </el-radio-button>
              <el-radio-button :label="3">
                Math
              </el-radio-button>
              <el-radio-button :label="4">
                Math Calculator
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <SmartInput
          :isNew="isNew"
          :sat_subject_id="this.practice.subject_id"
          :sat_id="0"
          :practiceId="id"
          :isTeacher="isTeacher"
          :isAdmin="isAdmin"
          :isCompany="isCompany"
          @addNewQuestionToPractice="addNewQuestionToPractice"
        ></SmartInput>
      </div>
      <template v-if="addMode === 1">
        <AllQuestions
          :practiceQuestions="practice.exam.exam_questions"
          @addQuestion="addQuestion"
          :isAdmin="isAdmin"
          :isTeacher="isTeacher"
          :isCompany="isCompany"
        ></AllQuestions>
      </template>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import AllQuestions from "@/views/satPractice/setQuestions.vue";
import SmartInput from "@/views/satAddQuestion/components/SmartInput";
import draggable from "vuedraggable";
import QuestionsTable from "@/views/allQuestions/QuestionsTable.vue";
import Company from "@/apis/company";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb,
    draggable,
    AllQuestions,
    SmartInput,
    QuestionsTable
  },

  mixins: [Common],

  props: [],
  data() {
    return {
      isDrill: "default",
      addMode: 1,
      showCreateNewQuestionDialog: false,
      companies: [],
      practice: {
        exam: {
          title: null,
          exam_questions: []
        },
        subject_type: null,
        subject_id: 5
      },
      composePractice: null,
      practices: [],
      subjects: [],
      question: {
        sat_passage_id: null,
        type: "default",
        content: "",
        explanation: "",
        sat_question_tag_ids: [],
        sat_options: [
          {
            order: 1,
            letter: "A",
            title: ""
          },
          {
            order: 2,
            letter: "B",
            title: ""
          },
          {
            order: 3,
            letter: "C",
            title: ""
          },
          {
            order: 4,
            letter: "D",
            title: ""
          }
        ],
        sat_answers: [
          {
            answers: []
          }
        ]
      },
      passage: {
        introduction: [],
        content: "",
        below_content: ""
      }
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    showQuestions() {
      let questions = [];
      if (
        this.practice.exam.exam_questions &&
        this.practice.exam.exam_questions.length > 0
      ) {
        this.practice.exam.exam_questions.forEach(question => {
          questions.push(question.question);
        });
      }
      return questions;
    },
    hasTaken() {
      return (
        this.practice.exam.user_exams_count &&
        this.practice.exam.user_exams_count > 0
      );
    },
    isNew() {
      // if (
      //   (this.practice && this.practice.exam.version === 1) ||
      //   this.isTeacher ||
      //   this.isCompany
      // ) {
      //   return 1;
      // } else {
      //   return 0;
      // }
      return 1;
    },
    PageTitle() {
      if (this.practice) {
        return this.practice.exam.title;
      } else {
        return "Loading...";
      }
    },
    id() {
      return Number(this.$route.query.id);
    },
    isTeacher() {
      return this.$route.name === "TeacherSatPracticeDetail";
    },
    isCompany() {
      return this.$route.name === "CompanySatPracticeDetail";
    },
    isAdmin() {
      return (
        this.$route.name === "SatPracticeDetail" ||
        this.$route.name === "SatPracticeFromFullDetail"
      );
    },
    isEdit() {
      let isEdit = 0;
      if (this.$route.query.isEdit) {
        isEdit = Number(this.$route.query.isEdit);
      }
      return isEdit;
    },
    subjectsNow() {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      let subjects = [];
      subjects = [
        { id: 13, name: "english" },
        { id: 14, name: "math" }
      ];
      return subjects;
    }
  },
  watch: {},

  async mounted() {
    const subjects = await SAT.getSubjects();
    this.subjects = subjects.sat_subjects;
    if (this.id && Number(this.id) > 0) {
      this.getPracticeDetail(0);
    } else {
      this.isEdit = true;
    }
    // if (this.$route.query.subject_id) {
    //   this.practice.subject_id = this.$route.query.subject_id;
    // }
    this.getCompanies();
  },

  methods: {
    getSubjectId(category) {
      let subject_id = null;

      let subjects = [
        {
          id: 1,
          name: "reading"
        },
        {
          id: 2,
          name: "writing"
        },
        {
          id: 3,
          name: "math"
        },
        {
          id: 4,
          name: "math calculator"
        },
        {
          id: 5,
          name: "english"
        }
      ];

      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;

      if (category === 1 || category === 5) {
        subject_id = 1;
      }
      if (category === 2 || category === 6) {
        subject_id = 2;
      }
      if (
        category === 3 ||
        category === 7 ||
        category === 12 ||
        category === 14
      ) {
        subject_id = 3;
      }
      if (category === 4 || category === 8) {
        subject_id = 4;
      }
      if (category === 11 || category === 13) {
        subject_id = 5;
      }
      return subject_id;
    },
    async updateStatus(id, status) {
      await SAT.updateStatus(id, {
        status: status
      });
      await this.$message({
        message: this.$t("management.Published!"),
        type: "success"
      });
      await this.getPracticeDetail();
    },
    async getCompanies() {
      const res = await Company.listCompany();
      this.companies = res.companies.data;
    },
    reload() {
      if (this.id && Number(this.id) > 0) {
        this.getPracticeDetail();
      }
    },
    isAnswer(answers, answer) {
      let isAnswer = false;
      answers.forEach(e => {
        if (e.answers && e.answers.length > 0) {
          e.answers.forEach(item => {
            if (item) {
              if (item.indexOf(answer) > -1) {
                isAnswer = true;
              }
            }
          });
        }
      });
      return isAnswer;
    },
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["ivy-question"]]);
      });
    },
    async setQuestionOrder() {
      let arr = [];
      this.practice.exam.exam_questions.forEach((item, index) => {
        arr.push({
          id: item.id
        });
      });
      await SAT.updatePracticeQuestionsOrder(this.id, {
        exam_questions: arr
      });
      await this.getPracticeDetail();
    },
    async addNewQuestionToPractice(type) {
      this.showCreateNewQuestionDialog = false;
      if (type === "addNewQuestion") {
        let box = document.getElementsByClassName("content-box")[0];
        box.scrollTo(0, 0);
        setTimeout(() => {
          this.showCreateNewQuestionDialog = true;
        }, 500);
      }
    },
    async updateInfo(data) {
      await SAT.updatePractice(this.id, {
        practice: {
          exam: {
            title: this.practice.exam.title,
            ...data
          }
        }
      });
      await this.getPracticeDetail();
    },
    async updateSubject(subject_id) {
      await SAT.updatePractice(this.id, {
        practice: {
          exam: {
            title: this.practice.exam.title,
            category: subject_id
          }
        }
      });
      await this.getPracticeDetail();
    },
    async updateDrill(type) {
      await SAT.updatePractice(this.id, {
        practice: {
          exam: {
            title: this.practice.exam.title
          },
          type: type
        }
      });
      await this.getPracticeDetail();
    },
    async removeQuestion(questionId) {
      this.$confirm(
        "Do you wish to remove this question from this practice test?",
        "",
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(async () => {
          await SAT.removePracticeQuestion(this.id, {
            exam_question_id: questionId
          });
          await this.getPracticeDetail();
          this.$message({
            type: "success",
            message: "Success!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    addQuestion(newQuestion) {
      this.addQuestionWithId(newQuestion.id);
    },
    async addQuestionWithId(id) {
      await SAT.addPracticeQuestion(this.id, {
        question_id: id,
        question_type: "Modules\\SAT\\Entities\\SatQuestion"
      });
      await this.getPracticeDetail();
    },
    async getPracticeDetail(type = 1) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;

      if (this.isEdit && type === 1) {
        this.$notify({
          title: "Success!",
          type: "success"
        });
      }
      const res = await SAT.getEditorPracticeDetail(this.id);
      // const res = await SAT.getPracticeDetail(this.id);
      this.practice = res.practice;
      this.isDrill =
        (this.practice && this.practice.exam.category === 11) ||
        this.practice.exam.category === 12
          ? "drill"
          : "default";

      // if (
      //   this.practice.exam.category === 12 ||
      //   this.practice.exam.category === 14
      // ) {
      //   this.practice.subject_id = 3;
      // }
      // if (
      //   this.practice.exam.category === 11 ||
      //   this.practice.exam.category === 13
      // ) {
      //   this.practice.subject_id = 5;
      // }

      this.practice.subject_id = this.getSubjectId(this.practice.exam.category);
      this.initMathJaxPlugin();
    },
    setQuestion(value) {
      this.question = { ...this.question, ...value };
    },
    setPassage(value) {
      this.passage = { ...this.passage, ...value };
    },
    selectPassage(passage) {
      this.question.sat_passage_id = passage.id;
      this.passage = passage;
      this.showCreateNewPassagesDialog = false;
    }
  }
};
</script>

<style scoped>
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.title-box {
  display: flex;
  align-items: center;
}
.title-box .title-box-input {
  width: 50%;
}
/* .title-box .title-box-button {
  width: 50px;
} */
::v-deep .el-form-item__content {
  /* line-height: 1.5; */
}
.question-card {
  cursor: move;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-collapse-item__content {
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header {
  padding: 0.5rem 0 0.5rem 1rem;
  height: auto;
  min-height: 48px;
  line-height: normal;
  align-items: start;
}
.show-question {
  font-family: DroidSerif;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  justify-content: center;
}
.options-box {
  font-family: DroidSerif;
}
.show-question-kbd {
  width: 2rem;
}
.show-question-kbd {
  padding-top: 0.1rem;
  width: 2rem;
}
.show-question-passage {
  flex: 1;
  overflow: hidden;
}

.show-passage {
  width: 100%;
  height: 20px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.is-active .show-passage {
  height: auto;
  word-break: normal;
  white-space: normal;
  overflow: auto;
  text-overflow: unset;
}
.quick-view-question {
  font-weight: 700;
}
::v-deep .quick-view-question img,
::v-deep .show-passage img {
  display: none;
}
::v-deep .is-active .quick-view-question img,
::v-deep .is-active .show-passage img {
  /* width: 50%; */
  display: inline-block;
}

::v-deep .el-collapse-item__header .el-icon-arrow-right {
  margin-top: 0.5rem;
  margin-left: 1rem;
  width: 15px;
}
/* ::v-deep .el-collapse-item__header p {
  margin: 0;
} */
.content-box {
  /* background: #fafafa; */
  padding: 1rem 0;
  height: 700px;
  width: 100%;
  overflow: auto;
}
.options-box {
  padding-left: 2rem;
}
.ivy-question-options {
  font-family: DroidSerif;
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
}
.ivy-question-options .optionText {
  flex: 1;
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.buttons {
  max-width: 185px;
}
.buttons .item {
  margin-left: 1rem;
}
.ivy-tag {
  margin: 0 10px 10px 10px;
}
::v-deep .MathJax .mrow {
  font-size: 1.1rem !important;
}
::v-deep .question-card .el-collapse-item__header:hover {
  cursor: move;
  opacity: 0.6;
}
</style>
