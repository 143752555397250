<template>
  <div>
    <el-button
      class="mb-3"
      type="success"
      v-show="!showAI"
      @click="showAI = !showAI"
    >
      <span>
        <i class="fas fa-smile-wink"></i>
        {{ $t("AddQuestions.Generate a question using AI") }}
      </span>
    </el-button>
    <el-card class="mb-3" v-show="showAI">
      <Heading
        style="flex: 1"
        :content="$t('AddQuestions.Generate a question using AI')"
        heading="h5"
      ></Heading>
      <hr />
      <div class="d-flex">
        <div style="flex: 1">
          <el-form
            :model="AIForm"
            :rules="rules"
            label-width="120px"
            ref="AIForm"
          >
            <el-form-item prop="tagIndex">
              <span slot="label">
                <router-link :to="{ name: 'QuestionTags' }">
                  <el-button type="text">
                    {{ $t("AddQuestions.Labels") }}
                    <i class="fa fa-edit"></i>
                  </el-button>
                </router-link>
              </span>
              <div class="d-flex">
                <div style="flex: 1">
                  <el-select
                    v-model="AIForm.tagIndex"
                    filterable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(tag, index) in tags"
                      :key="tag.id"
                      :label="`[${tag.type}] - ${tag.name}`"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="ml-3"
                  v-if="AIForm.tagIndex !== null && AIForm.tagIndex > -1"
                >
                  <el-button type="success" @click="updateTag(AIForm.tagIndex)">
                    <i
                      class="el-icon-upload
      "
                    ></i>
                    {{ $t("AddQuestions.Update the label for this prompt") }}
                  </el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="Prompt" prop="prompt" required>
              <el-input
                type="textarea"
                :rows="8"
                :placeholder="promptPlaceholder"
                v-model="AIForm.prompt"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="mt-2 text-right">
                <el-button type="danger" @click="showAI = !showAI" plain>
                  {{ $t("alert.cancel") }}
                </el-button>
                <el-button type="success" @click="submitForm('AIForm')">
                  <i class="fas fa-smile-wink"></i>
                  {{ $t("AddQuestions.Generate") }}
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat.js";

export default {
  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      showAI: false,
      tags: [],
      AIForm: {
        tagIndex: null,
        prompt: null
      },
      promptPlaceholder: `Generate 1 digital SAT vocabulary question(s) like this:
Passage:bringing the principles of the Greek stoic philosophers to the masses through his widely published _____
Separate line: -
Which choice completes the text so that it conforms to the conventions of Standard English?
A)"Meditations."
B), "Meditations."
C)"Meditations".
D), "Meditations".
A
Explanation: XXX`,
      rules: {
        prompt: [{ required: true, message: "Prompt", trigger: "blur" }]
      }
    };
  },
  computed: {},
  watch: {
    "AIForm.tagIndex": {
      handler(index) {
        this.AIForm.prompt = this.tags[index].prompt;
      }
    }
  },

  async mounted() {
    await this.getTags();
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("getQuestionsByAI", this.AIForm.prompt);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    },
    async updateTag(index) {
      let tag = this.tags[index];
      await SAT.updateSATQuestionTags(tag.id, {
        type: tag.type,
        name: tag.name,
        prompt: this.AIForm.prompt
      });
      await this.getTags();
      this.$message({
        message: "Success!",
        type: "success"
      });
    }
  }
};
</script>

<style scoped></style>
