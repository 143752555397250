<template>
  <div>
    <SearchDetail
      :isAdmin="isAdmin"
      :isTeacher="isTeacher"
      :isCompany="isCompany"
      :search="search"
      @setSearchToLink="setSearchToLink"
    />
    <hr />
    <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
      <span slot="title">
        Results: <b>{{ resultsTotal }}</b>
      </span>
    </el-alert>
    <div v-if="questions">
      <div v-if="questions.length > 0">
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <QuestionsTable
          :isAdd="true"
          :isEdit="true"
          :showQuestion="true"
          :showPassage="true"
          :showOptions="true"
          :showAnswer="true"
          :showExplanation="true"
          :showLabels="true"
          :questions="questions"
          :search="search.search"
          @addQuestion="addQuestion"
          :isAdmin="isAdmin"
        >
        </QuestionsTable>
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </div>
      <el-empty v-else description="No Data"></el-empty>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import SearchDetail from "@/views/allQuestions/Search.vue";
import QuestionCard from "@/views/viewSatTest/components/QuestionCard.vue";
import QuestionsTable from "@/views/allQuestions/QuestionsTable.vue";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {},

  components: {
    SearchDetail,
    // QuestionCard,
    QuestionsTable
  },

  mixins: [Common],

  props: ["practiceQuestions", "isAdmin", "isCompany", "isTeacher"],
  data() {
    return {
      // showAnswer: false,
      // showExplanation: false,
      // showOptions: false,
      // showPassage: false,
      // showQuestion: false,
      // showLabels: false,
      search: {
        isNew: 1,
        test_types: [],
        originalSubjects: [],
        subjects: [],
        tags: [],
        difficulty: null,
        source: "SATMocks",
        company_id: null,
        search: null
      },
      keyword: null,
      subjects: [],
      tags: [],
      questions: null,
      practice: null,
      page: 1,
      resultsTotal: 0,
      pageSize: 0
    };
  },
  computed: {
    subjectsNow() {
      let subjects = [];
      if (this.search.isNew === 1) {
        subjects = [this.subjects[4], this.subjects[2]];
      } else {
        subjects = [
          this.subjects[0],
          this.subjects[1],
          this.subjects[2],
          this.subjects[3]
        ];
      }
      return subjects;
    },
    helper() {
      return helper;
    },
    id() {
      return this.$route.query.id;
    },
    isAdd() {
      return true;
    }
  },
  watch: {
    practiceQuestions() {
      // this.getQuestions(this.page);
      this.checkQuestions();
    },
    "search.isNew"(val) {
      this.search.subjects = [];
      this.subjectsNow.forEach(subject => {
        this.search.subjects.push(subject.id);
      });
    }
  },

  async mounted() {
    const subjects = await SAT.getSubjects();
    this.subjects = subjects.sat_subjects;
    this.search.subjects = [];
    this.subjectsNow.forEach(subject => {
      this.search.subjects.push(subject.id);
    });
    await this.getTags();
    await this.getQuestions(1);
    if (this.id && Number(this.id) > 0) {
      await this.getPracticeDetail();
      this.checkQuestions();
    }
  },

  methods: {
    async addQuestion(question) {
      await this.$emit("addQuestion", question);
      // this.checkQuestions();
    },
    setPage(page) {
      this.search.page = page;
      this.page = page;
      this.getQuestions(page);
    },
    setSearchToLink(val) {
      this.getQuestions(1);
    },
    checkQuestions() {
      console.log(this.practiceQuestions);
      this.questions.forEach(question => {
        question["isIn"] = false;
        let isIn = 0;
        this.practiceQuestions.forEach(questionIn => {
          if (question.id === questionIn.question_id) {
            isIn++;
          }
        });
        if (isIn > 0) {
          question["isIn"] = true;
        }
      });
      console.log(this.questions);
    },
    async getQuestions(page) {
      this.questions = null;
      this.keyword = this.search.search;
      const res = await SAT.getSatPracticeQuestions({
        page: page,
        per_page: 10,
        sat_question_tag_ids: this.search.tags,
        sat_subject_ids: this.search.subjects,
        keyword: this.search.search,
        is_new: this.search.isNew,
        source: this.getSource(this.search.source),
        company_id: this.getCompanyId(this.search.source),
        difficulty: this.search.difficulty
      });
      res.questions.data.forEach(question => {
        question["isIn"] = false;
      });
      this.questions = res.questions.data;
      this.pageSize = res.questions.per_page;
      this.resultsTotal = res.questions.total;
      this.checkQuestions();
    },
    getSource(source) {
      let NewSource = null;
      if (source) {
        if (source === "SATMocks") {
          NewSource = 1;
        } else if (source === "Official") {
          NewSource = 2;
        } else {
          NewSource = 3;
        }
      }
      return NewSource;
    },
    getCompanyId(source) {
      let company = null;
      if (source) {
        if (source === "SATMocks") {
          company = null;
        } else if (source === "Official") {
          company = null;
        } else {
          company = source;
        }
      }
      return company;
    },
    async getPracticeDetail() {
      const res = await SAT.getPracticeDetail(this.id);
      this.practice = res.practice;
    },
    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    }
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep .el-tabs--border-card {
  box-shadow: none;
}
.search {
  margin: 20px 0px;
}

.questions {
  margin: 20px 0px;
}
.questions-list {
  padding: 1rem;
  /* border: 2px solid var(--themeColor); */
}
.table-box {
  margin-top: 1rem;
  height: calc(100vh - 485px);
  overflow: auto;
}

.leftQuestionCountTip {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-question-btn {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
