<template>
  <div>
    <div ref="ivy-question" v-if="smartQuestions.length > 0">
      <el-collapse accordion>
        <el-collapse-item
          v-for="(question, index) in smartQuestions"
          :key="question.id"
        >
          <template slot="title">
            <div class="show-question">
              <div class="show-question-passage">
                <h6
                  v-if="question.passage && question.passage.content"
                  class="show-passage"
                  v-html="question.passage.content"
                ></h6>
                <h6
                  class="m-0 quick-view-question"
                  v-html="question.question.content"
                ></h6>
              </div>
              <div class="buttons text-right">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Edit"
                  placement="top"
                >
                  <el-button
                    type="success"
                    size="mini"
                    @click="editQuestion(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Remove"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="mini"
                    @click.stop="$emit('removeQuestion')"
                  >
                    <i class="fas fa-times-circle"></i>
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </template>
          <div class="options-box mb-2">
            <ul
              class="ivy-question-options"
              v-if="question.question.type === 'default'"
            >
              <li
                class="option"
                v-for="(option, index) in question.question.sat_options"
                :key="index"
              >
                <div style="width:1.5rem">
                  <span
                    class="text-success"
                    v-show="
                      isAnswer(question.question.sat_answers, option.letter)
                    "
                  >
                    <span></span>
                    <i class="fas fa-check-circle"></i>
                  </span>
                </div>
                <span style="width:1.3rem">{{ `${option.letter}) ` }}</span>
                <div class="optionText" v-html="option.title"></div>
              </li>
            </ul>
            <template v-else>
              <h6>
                <b>Answer:</b>
                <span
                  class="ml-1"
                  v-for="(answer, index) in question.question.sat_answers"
                  :key="index"
                >
                  {{ answer.answers[0] }}
                  <span v-if="index < question.question.sat_answers.length - 1">
                    ,
                  </span>
                </span>
              </h6>
            </template>
            <div
              v-if="
                question.question.explanation &&
                  question.question.explanation !== ''
              "
              class="mb-2 mt-2"
            >
              <h6>
                <b>Explanation:</b>
              </h6>
              <div v-html="question.question.explanation"></div>
            </div>
            <!-- <PreviewQuestion class="mt-3" :passage="question.passage" :question="question.question">
            </PreviewQuestion> -->
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-dialog
      class="edit-smart-question-dialog"
      title="Edit"
      :visible.sync="dialogVisible"
      width="90%"
      top="2vh"
      
      append-to-body
    >
      <div class="row" v-if="smartIndex > -1" style="height: 80vh;overflow: auto;">
        <div class="mb-4 col-md-12 col-lg-6">
          <el-card>
            <h5>
              Passage
            </h5>
            <hr />
            <AddPassageModal
              v-if="question && passage"
              :isEdit="true"
              :passage="smartQuestions[smartIndex].passage"
              :questionId="smartQuestions[smartIndex].question.sat_passage_id"
              :isNew="true"
              @setPassage="setPassage"
            />
          </el-card>
        </div>
        <div class="col-md-12 col-lg-6">
          <AddQuestionModal
            v-if="question"
            :question="smartQuestions[smartIndex].question"
            :passage="smartQuestions[smartIndex].passage"
            @setQuestion="setQuestion"
          ></AddQuestionModal>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">
          {{ $t("alert.cancel") }}
        </el-button>
        <el-button type="success" @click="dialogVisible = false">
          {{ $t("alert.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import AddQuestionModal from "@/views/satAddQuestion/components/AddQuestion.vue";
import AddPassageModal from "@/views/satAddQuestion/components/PassageForm";
import PreviewQuestion from "@/views/satAddQuestion/components/PreviewQuestion";

export default {
  components: { AddQuestionModal, AddPassageModal },

  mixins: [],

  props: ["smartQuestions"],
  data() {
    return {
      dialogVisible: false,
      smartIndex: null,
      question: null,
      passage: null
    };
  },
  computed: {},
  watch: {
    smartQuestions() {
      this.setMath("ivy-question");
    }
  },

  mounted() {
    this.setMath("ivy-question");
  },

  methods: {
    isAnswer(answers, answer) {
      let isAnswer = false;
      answers.forEach(e => {
        if (e.answers && e.answers.length > 0) {
          e.answers.forEach(item => {
            if (item.indexOf(answer) > -1) {
              isAnswer = true;
            }
          });
        }
      });
      return isAnswer;
    },
    setQuestion(value) {
      console.log(value);
      this.smartQuestions[this.smartIndex].question = {
        ...this.question,
        ...value
      };
    },
    setPassage(value) {
      console.log(value);
      this.smartQuestions[this.smartIndex].passage = {
        ...this.passage,
        ...value
      };
    },
    editQuestion(index) {
      this.smartIndex = index;
      this.question = this.smartQuestions[index].question;
      this.passage = this.smartQuestions[index].passage;
      this.dialogVisible = true;
    },
    setMath(ref) {
      this.$nextTick(() => {
        let tag = this.$refs[ref];
        if (this.$mathJaxSAT.isMathjaxConfig) {
          this.$mathJaxSAT.initMathjaxConfig();
        }
        if (tag) {
          this.$mathJaxSAT.MathQueue([tag]);
        }
      });
    }
  }
};
</script>

<style scoped>
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.title-box {
  display: flex;
  align-items: center;
}
.title-box .title-box-input {
  width: 50%;
}
/* .title-box .title-box-button {
  width: 50px;
} */
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-collapse-item__content {
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header {
  padding: 0.5rem 0 0.5rem 1rem;
  height: auto;
  min-height: 48px;
  line-height: normal;
  align-items: start;
}
.show-question {
  font-family: DroidSerif;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  justify-content: center;
}
.options-box {
  font-family: DroidSerif;
}
.show-question-kbd {
  width: 2rem;
}
.show-question-kbd {
  padding-top: 0.1rem;
  width: 2rem;
}
.show-question-passage {
  flex: 1;
  overflow: hidden;
}

.show-passage {
  width: 100%;
  height: 20px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.is-active .show-passage {
  height: auto;
  word-break: normal;
  white-space: normal;
  overflow: auto;
  text-overflow: unset;
}
.quick-view-question {
  font-weight: 700;
}
::v-deep .quick-view-question img,
::v-deep .show-passage img {
  display: none;
}
::v-deep .is-active .quick-view-question img,
::v-deep .is-active .show-passage img {
  /* width: 50%; */
  display: inline-block;
}

::v-deep .el-collapse-item__header .el-icon-arrow-right {
  margin-top: 0.5rem;
  margin-left: 1rem;
  width: 15px;
}
/* ::v-deep .el-collapse-item__header p {
  margin: 0;
} */
.content-box {
  background: #fafafa;
  padding: 1rem 0;
  height: 700px;
  overflow: auto;
}
.ivy-question-options {
  font-family: DroidSerif;
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  font-size: 16px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
}
.ivy-question-options .optionText {
  flex: 1;
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.buttons {
  width: 125px;
}
.buttons .item {
  margin-left: 1rem;
}
.ivy-tag {
  margin: 0 10px 10px 10px;
}
::v-deep .MathJax .mrow {
  font-size: 1.1rem !important;
}

::v-deep .edit-smart-question-dialog .el-dialog__body {
  height: 800px;
  overflow: auto;
}
</style>
