<template>
  <div class="newQuestion">
    <template>
      <el-form ref="form" label-width="180px">
        <el-form-item required v-if="isAdmin">
          <span slot="label">
            {{ $t("AddQuestions.Source") }}
          </span>
          <!-- <el-select
            :placeholder="$t('AddQuestions.Choose question source')"
            v-model="question.source"
            style="width:100%"
            filterable
          >
            <el-option
              :label="getProfile.company.name"
              :value="getProfile.company.id"
            ></el-option>
            <el-option label="SATMocks" :value="1"></el-option>
            <el-option label="Official" :value="2"></el-option>
          </el-select> -->
          <el-radio-group
            v-model="question.source"
            v-if="isAdmin"
          >
            <el-radio-button label="SATMocks" :value="1">
              SATMocks
            </el-radio-button>
            <el-radio-button label="Official" :value="2">
              Official
            </el-radio-button>
          </el-radio-group>
          <el-select
            v-model="question.source"
            style="width: 100%;"
            v-if="false"
          >
            <!--
              1:SATMocks
              2:Official
              3:私人的題目或是機構的題目
            -->
            <el-option-group label="SATMocks">
              <el-option label="SATMocks" value="1">
                <i class="el-icon-s-management" />
                SATMocks
              </el-option>
              <el-option label="Official" value="2">
                <i class="el-icon-s-management" />
                Official
              </el-option>
            </el-option-group>
            <el-option-group label="Companies">
              <el-option
                :key="company.id"
                v-for="company in companies"
                :label="company.name"
                :value="company.id"
              >
                <i class="el-icon-s-management" />
                {{ company.name }}
              </el-option>
            </el-option-group>
          </el-select>
          <div class="d-flex" v-if="isTeacher || isCompany">
            <div>
              <el-radio-group v-model="question.company_id">
                <el-radio-button label="1" value="1">
                  SATMocks
                </el-radio-button>
                <el-radio-button label="2" value="2">
                  Official
                </el-radio-button>
                <el-radio-button :label="getProfile.company.id">
                  {{ getProfile.company.name }}
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item required v-show="!isEdit">
          <span slot="label">
            {{ $t("AIQuestions.Method") }}
          </span>
          <el-radio-group v-model="inputType">
            <el-radio-button :label="1">
              {{ $t("AIQuestions.Single Question") }}
            </el-radio-button>
            <el-radio-button :label="2">
              <el-popover placement="top" trigger="hover">
                <el-alert type="success" :closable="false">
                  <ul style="margin-bottom:0; line-height: 2">
                    <li>
                      {{ $t("AIQuestions.Bulk import questions in one shot") }}
                    </li>
                  </ul>
                </el-alert>
                <span slot="reference">
                  {{ $t("AIQuestions.Multiple Questions") }}
                  <i class="fas fa-question-circle"></i>
                </span> </el-popover
            ></el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </template>
    <template v-if="inputType === 1">
      <el-form label-width="180px">
        <el-form-item required>
          <span slot="label">
            {{ $t("AddQuestions.Type") }}
          </span>
          <el-radio-group v-model="question.type">
            <el-radio-button label="default">
              {{ $t("AddQuestions.Multiple Choice") }}
            </el-radio-button>
            <el-radio-button label="math">
              {{ $t("AddQuestions.Grid-In") }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <el-popover placement="top" trigger="hover">
              <div>
                <el-alert type="success" :closable="false" class="explanation">
                  {{ $t("AIQuestions.Follow the question format") }}
                </el-alert>
              </div>
              <span
                class="text-success"
                style="cursor:pointer"
                slot="reference"
              >
                {{ $t("AIQuestions.Question Text") }}
                <i class="fas fa-question-circle"></i
              ></span>
            </el-popover>
          </span>
          <AIQuestions v-if="false" :tags="tags" @getQuestionsByAI="getQuestionsByAI" />
          <el-alert class="mb-3" type="warning" show-icon :closable="false">
            <div slot="title">
              <div>
                <span
                  v-html="$t('AIQuestions.Only input 1 question at a time')"
                ></span>
              </div>
              <div>
                <span v-html="$t('AIQuestions.Separate the passage')"></span>
              </div>
              <div>
                <span
                  v-html="
                    $t('AIQuestions.Separate paragraphs with a blank line')
                  "
                ></span>
              </div>
            </div>
          </el-alert>
          <div
            v-loading="smartOneLoading"
            :element-loading-text="$t('AIQuestions.Analyzing using AI')"
          >
            <div v-show="this.question.type === 'default'">
              <div class="mb-3 smartEditor">
                <Ckeditor
                  :placeholder="placeholder[0]"
                  v-model="defaultTextarea"
                ></Ckeditor>
              </div>
              <div>
                <el-button
                  type="success"
                  @click="smartOptions(replaceSmartString(defaultTextarea))"
                >
                  {{ $t("AIQuestions.Analyze text to create the question") }}
                </el-button>
              </div>
            </div>
            <div v-show="this.question.type === 'math'">
              <div class="mb-3 smartEditor">
                <Ckeditor
                  :placeholder="placeholder[1]"
                  v-model="mathTextarea"
                ></Ckeditor>
              </div>
              <div>
                <el-button
                  type="success"
                  @click="smartOptions(replaceSmartString(mathTextarea))"
                >
                  {{ $t("AIQuestions.Analyze text to create the question") }}
                </el-button>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <hr />
      <div class="row view-question-detail">
        <div
          class="col-md-12 col-lg-6 mb-4 questions-content"
          v-if="sat_subject_id !== 3 && sat_subject_id !== 4"
        >
          <el-card>
            <h5>
              Passage
            </h5>
            <hr />
            <AddPassageModal
              :isEdit="isEdit"
              :passage="passage"
              :questionId="question.sat_passage_id"
              :isNew="isNew"
              @setPassage="setPassage"
              @selectPassage="selectPassage"
            />
          </el-card>
        </div>
        <div class="col-sm-3" v-else></div>
        <div class="col-md-12 col-lg-6">
          <AddQuestionModal
            :passage="passage"
            :question="question"
            @setQuestion="setQuestion"
          ></AddQuestionModal>
        </div>
      </div>
      <!-- <hr /> -->
      <PreviewQuestion
        class="mt-4"
        :showPassage="passage"
        :showQuestion="question"
      >
      </PreviewQuestion>
      <!-- <hr /> -->
      <div class="text-center mt-4">
        <el-button v-if="isEdit" type="success" @click="saveAll('back')">
          <i class="fas fa-save"></i>
          {{ $t("AIQuestions.Save") }}
        </el-button>
        <template v-else>
          <el-button type="success" @click="saveAll('back')">
            <i class="fas fa-plus"></i>
            Add
          </el-button>
          <el-button type="success" @click="saveAll('addNewQuestion')">
            <i class="fas fa-plus"></i>
            {{ $t("AIQuestions.Save and Create Another") }}
          </el-button>
        </template>
      </div>
    </template>
    <template v-if="inputType === 2">
      <el-form label-width="180px">
        <el-form-item>
          <div slot="label">
            {{ $t("AddQuestions.Type") }}
          </div>
          <el-radio-group v-model="smartType">
            <el-radio-button label="default">
              {{ $t("AddQuestions.Multiple Choice") }}</el-radio-button
            >
            <el-radio-button label="math">{{
              $t("AddQuestions.Grid-In")
            }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <div slot="label">
            {{ $t("AddQuestions.Labels") }}
            <router-link :to="{ name: 'QuestionTags' }"
              ><i class="fa fa-edit"></i
            ></router-link>
          </div>
          <el-select
            v-model="smartTags"
            placeholder="Select"
            multiple
            filterable
            style="width:100%"
          >
            <el-option
              v-for="tag in tags"
              :key="tag.id"
              :label="`[${tag.type}] - ${tag.name}`"
              :value="tag.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <el-popover placement="top" trigger="hover">
              <div>
                <el-alert type="success" :closable="false" class="explanation">
                  {{ $t("AIQuestions.Follow the question format") }}
                </el-alert>
              </div>
              <span
                class="text-success"
                style="cursor:pointer"
                slot="reference"
              >
                {{ $t("AIQuestions.Question Text") }}
                <i class="fas fa-question-circle"></i
              ></span>
            </el-popover>
          </span>
          <AIQuestions v-if="false" :tags="tags" @getQuestionsByAI="getQuestionsByAI" />
          <el-alert class="mb-3" type="warning" show-icon :closable="false">
            <div slot="title">
              <div>
                <span
                  v-html="$t('AIQuestions.Only input 1 question at a time')"
                ></span>
              </div>
              <div>
                <span v-html="$t('AIQuestions.Separate the passage')"></span>
              </div>
              <div>
                <span
                  v-html="
                    $t('AIQuestions.Separate paragraphs with a blank line')
                  "
                ></span>
              </div>
              <div>
                <span
                  v-html="
                    $t('AIQuestions.Separate questions with 2 blank lines')
                  "
                ></span>
              </div>
            </div>
          </el-alert>
          <div
            v-loading="smartOneLoading"
            :element-loading-text="$t('AIQuestions.Analyzing using AI')"
          >
            <div v-show="this.smartType === 'default'">
              <div class="mb-3 smartEditor">
                <Ckeditor
                  :placeholder="placeholders[0]"
                  v-model="smartQuestionsText"
                ></Ckeditor>
              </div>
              <div>
                <el-button
                  type="success"
                  @click="
                    smartAllQuestions(replaceSmartString(smartQuestionsText))
                  "
                >
                  {{ $t("AIQuestions.Analyze text to create the question") }}
                </el-button>
              </div>
            </div>
            <div v-show="this.smartType === 'math'">
              <div class="mb-3 smartEditor">
                <Ckeditor
                  :placeholder="placeholders[1]"
                  v-model="smartMathQuestionsText"
                ></Ckeditor>
              </div>
              <div>
                <el-button
                  type="success"
                  @click="
                    smartAllQuestions(
                      replaceSmartString(smartMathQuestionsText)
                    )
                  "
                >
                  {{ $t("AIQuestions.Analyze text to create the question") }}
                </el-button>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div class="view-question-detail">
        <div class="row" v-if="smartQuestions && smartQuestions.length > 0">
          <div class="col-sm-12">
            <hr />
            <el-alert
              class="mb-3"
              :title="$t('AIQuestions.Do not leave')"
              type="warning"
              effect="dark"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
          <!-- <div class="col-sm-2"></div> -->
          <div class="col-sm-12">
            <el-card>
              <Heading
                heading="h5"
                :content="$t('AIQuestions.Result')"
              ></Heading>
              <div class="mb-2">
                <small class="text-warning">
                  <b>{{ $t("AIQuestions.Note") }}</b>
                </small>
              </div>
              <SmartQuestions
                @removeQuestion="removeQuestion"
                :smartQuestions="smartQuestions"
              ></SmartQuestions>
              <div class="mt-4 text-center">
                <el-button
                  slot="button"
                  type="success"
                  @click="saveSmartQuestions"
                >
                  {{ $t("AIQuestions.Bulk import") }}
                </el-button>
              </div>
              <el-dialog
                v-if="totalProgress > 0"
                :title="$t('AIQuestions.Importing')"
                :visible.sync="showProgress"
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                width="40%"
                append-to-body
              >
                <div class="text-center">
                  <el-progress
                    type="circle"
                    :percentage="
                      `${parseInt((progress / totalProgress) * 100)}`
                    "
                  ></el-progress>
                </div>
                <el-alert
                  class="mt-3"
                  v-if="progress < totalProgress"
                  type="warning"
                  show-icon
                  :closable="false"
                >
                  {{ $t("AIQuestions.do not leave this page") }}
                  <br />
                  <span
                    v-html="
                      $t('AIQuestions.Imported', { counts: this.progress })
                    "
                  />
                </el-alert>
                <el-alert
                  class="mt-3"
                  v-else
                  type="success"
                  show-icon
                  :closable="false"
                >
                  <span
                    v-html="
                      $t('AIQuestions.Completed', { counts: this.progress })
                    "
                  />
                </el-alert>
                <span slot="footer" class="dialog-footer">
                  <el-button type="success" @click="reSetSmartQuestions">
                    {{ $t("alert.confirm") }}
                  </el-button>
                </span>
              </el-dialog>
            </el-card>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Ckeditor from "@/components/ckeditor/ckeditor5.vue";
import AddQuestionModal from "@/views/satAddQuestion/components/AddQuestion.vue";
import AddPassageModal from "@/views/satAddQuestion/components/PassageForm";
import SmartQuestions from "@/views/satAddQuestion/components/SmartQuestions";
import PreviewQuestion from "@/views/satAddQuestion/components/PreviewQuestion";
import AIQuestions from "@/views/satAddQuestion/components/AIQuestions";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import Company from "@/apis/company";

export default {
  components: {
    Ckeditor,
    AddQuestionModal,
    AddPassageModal,
    SmartQuestions,
    AIQuestions,
    PreviewQuestion
  },

  mixins: [Common],

  props: [
    "isNew",
    "isEdit",
    "sat_subject_id",
    "sat_id",
    "practiceId",
    "isTeacher",
    "isAdmin",
    "isCompany"
  ],
  data() {
    return {
      companies: [],
      smartOneLoading: false,
      showProgress: false,
      progress: 0,
      tags: [],
      smartTags: null,
      defaultTextarea: "",
      mathTextarea: "",
      smartType: "default",
      smartQuestionsText: "",
      smartMathQuestionsText: "",
      smartQuestions: [],
      inputType: 1,
      textarea: "",
      sat: {
        is_new: 1,
        title: ""
      },
      sats: [],
      title: 0,
      needPassage: false,
      showCreateNewPassagesDialog: false,
      showCreateSATDialog: false,
      // subjects: [],
      question: {
        sat_passage_id: null,
        type: "default",
        content: "",
        explanation: "",
        source_slug: "official",
        source: 1,
        difficulty: null,
        sat_question_tag_ids: [],
        correct_count: 0,
        answered_count: 0,
        sat_options: [
          {
            order: 1,
            letter: "A",
            title: ""
          },
          {
            order: 2,
            letter: "B",
            title: ""
          },
          {
            order: 3,
            letter: "C",
            title: ""
          },
          {
            order: 4,
            letter: "D",
            title: ""
          }
        ],
        sat_answers: [
          {
            answers: []
          }
        ]
      },
      passage: {
        introduction: [],
        content: "",
        below_content: ""
      }
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    totalProgress() {
      return this.smartQuestions.length;
    },
    placeholder() {
      return [
        `“Ghosts of the Old Year” is an early 1900s poem by James Weldon Johnson. In the poem, the speaker describes experiencing an ongoing cycle of anticipation followed by regretful reflection: ______
-
Which quotation from “Ghosts of the Old Year” most effectively illustrates the claim?
A) "The snow has ceased its fluttering flight, / The wind sunk to a whisper light, / An ominous stillness fills the night, / A pause—a hush."
B) "And so the years go swiftly by, / Each, coming, brings ambitions high, / And each, departing, leaves a sigh / Linked to the past."
C) "What does this brazen tongue declare, / That falling on the midnight air / Brings to my heart a sense of care / Akin to fright?"
D) "It tells of many a squandered day, / Of slighted gems and treasured clay, / Of precious stores not laid away, / Of fields unreaped."
B
Choice B is the best answer. The passage....`,
        `The \`y\`-intercept of the graph of \`y = −6x − 32\` in the \`xy\`-plane is \`(0, y)\`. What is the value of \`y\`?
-32
Choice B is the best answer. The passage...`
      ];
    },
    placeholders() {
      return [
        `“Ghosts of the Old Year” is an early 1900s poem by James Weldon Johnson. In the poem, the speaker describes experiencing an ongoing cycle of anticipation followed by regretful reflection: ______
-
Which quotation from “Ghosts of the Old Year” most effectively illustrates the claim?
A) "The snow has ceased its fluttering flight, / The wind sunk to a whisper light, / An ominous stillness fills the night, / A pause—a hush."
B) "And so the years go swiftly by, / Each, coming, brings ambitions high, / And each, departing, leaves a sigh / Linked to the past."
C) "What does this brazen tongue declare, / That falling on the midnight air / Brings to my heart a sense of care / Akin to fright?"
D) "It tells of many a squandered day, / Of slighted gems and treasured clay, / Of precious stores not laid away, / Of fields unreaped."
B
Choice B is the best answer. The passage....


“Ghosts of the Old Year” is an early 1900s poem by James Weldon Johnson. In the poem, the speaker describes experiencing an ongoing cycle of anticipation followed by regretful reflection: ______
-
Which quotation from “Ghosts of the Old Year” most effectively illustrates the claim?
A) "The snow has ceased its fluttering flight, / The wind sunk to a whisper light, / An ominous stillness fills the night, / A pause—a hush."
B) "And so the years go swiftly by, / Each, coming, brings ambitions high, / And each, departing, leaves a sigh / Linked to the past."
C) "What does this brazen tongue declare, / That falling on the midnight air / Brings to my heart a sense of care / Akin to fright?"
D) "It tells of many a squandered day, / Of slighted gems and treasured clay, / Of precious stores not laid away, / Of fields unreaped."
B
Choice B is the best answer. The passage....`,
        `The \`y\`-intercept of the graph of \`y = −6x − 32\` in the \`xy\`-plane is \`(0, y)\`. What is the value of \`y\`?
-32
Choice B is the best answer. The passage...


The \`y\`-intercept of the graph of \`y = −6x − 32\` in the \`xy\`-plane is \`(0, y)\`. What is the value of \`y\`?
-32
Choice B is the best answer. The passage...`
      ];
    },
    questionId() {
      return this.$route.query.questionId;
    }
    // isEdit() {
    //   return this.questionId && this.questionId > 0;
    // }
  },
  watch: {},

  async mounted() {
    if (this.questionId && this.questionId > 0) {
      if (this.isEdit) {
        this.scrollToQuestion();
      }
      const res = await SAT.getQuestionDetail(this.questionId);
      const sat_question = res.sat_question;
      this.isNew = sat_question.is_new;
      this.question = { ...this.question, ...sat_question };
      this.passage = { ...this.passage, ...sat_question.sat_passage };
      if (
        sat_question.sat_question_tags &&
        sat_question.sat_question_tags.length > 0
      ) {
        sat_question.sat_question_tags.forEach(e => {
          this.question.sat_question_tag_ids.push(e.id);
        });
      }
      this.sat_id = sat_question.sat_sat_subject?.sat.id;
    } else {
      this.sat_id = 496;
    }
    if (Number(this.$route.query.isNew) > -1) {
      this.isNew = Number(this.$route.query.isNew);
      if (this.isNew === 1) {
        this.sat_subject_id = 5;
      }
      if (this.isNew === 0) {
        this.sat_subject_id = 1;
      }
    }
    // await this.getSATS();
    // await this.getSubjects();
    this.getCompanies();
    await this.getTags();
  },
  methods: {
    async getCompanies() {
      const res = await Company.listCompany();
      this.companies = res.companies.data;
    },
    scrollToQuestion() {
      let rightContent = document.getElementsByClassName("right-content")[0];
      let box = document.getElementsByClassName("view-question-detail")[0];
      rightContent.scrollTo(0, box.offsetTop - 80);
    },
    setPromptOne() {
      if (this.question.type === "default") {
        if (this.promptOneHasPassage) {
          this.promptOne = `Generate a digital SAT vocabulary question like this:
Passage:bringing the principles of the Greek stoic philosophers to the masses through his widely published _____
Separate line: -
Which choice completes the text so that it conforms to the conventions of Standard English?
A)"Meditations."
B), "Meditations."
C)"Meditations".
D), "Meditations".
A
Explanation: XXX`;
        } else {
          this.promptOne = `Generate a digital SAT vocabulary question like this:
Which word is defined as having a negative or critical attitude or tone?
A) Despondent
B) Egregious
C) Cynical
D) Sagacious
C
Explanation:xxx`;
        }
      } else {
        this.promptOne = `Generate a Math digital SAT vocabulary question like this:
The \`y\`-intercept of the graph of \`y = −6x − 32\` in the \`xy\`-plane is \`(0, y)\`. What is the value of \`y\`?
-32
Explanation: XXX`;
      }
    },
    async getQuestionsByAI(prompt) {
      this.smartOneLoading = true;
      const res = await SAT.getQuestionsByAI({ prompt: prompt });
      let content = res.text.choices ? res.text.choices[0].message.content : "";
      this.smartOneLoading = false;
      console.log(res);
      console.log(content);
      if (content) {
        content = this.replaceAll(content, "\n", "<br>");
        if (this.inputType === 1) {
          this.defaultTextarea = content;
          this.mathTextarea = content;
        } else {
          this.smartQuestionsText = content;
        }
        this.$message({
          showClose: true,
          message: "Success!",
          type: "success",
          duration: 6000
        });
      } else {
        this.$message({
          showClose: true,
          title: "",
          message: res.text ? `Error:${res.text.error.message}` : "Error: null",
          type: "error",
          duration: 6000
        });
      }
    },
    reSetSmartQuestions() {
      this.smartQuestions = [];
      this.showProgress = false;
    },
    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    },
    selectVersion(isNew) {
      this.$router.replace({
        name: "SatAddQuestion",
        query: {
          isNew: isNew
        }
      });
    },
    // async getSubjects() {
    //   const subjects = await SAT.getSubjects();
    //   this.subjects = subjects.sat_subjects;
    // },
    // async getSATS() {
    //   const res = await SAT.getSATS({
    //     is_new: this.isNew
    //   });
    //   this.sats = res.sats;
    // },
    setQuestion(value) {
      this.question = { ...this.question, ...value };
    },
    setPassage(value) {
      console.log(value);
      this.passage = { ...this.passage, ...value };
    },
    selectPassage(passage) {
      this.question.sat_passage_id = passage.id;
      this.passage = passage;
      this.showCreateNewPassagesDialog = false;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    closePassageDialog() {
      this.showCreateNewPassagesDialog = false;
    },
    async checkPassage() {
      if (this.question.sat_passage_id && this.question.sat_passage_id > 0) {
        const res = await SAT.getPassage(this.question.sat_passage_id);
        this.passage = res.sat_passage;
      } else {
        this.passage = {
          title: "",
          introduction: {
            intro: "",
            below_content: ""
          },
          content: ""
        };
      }
      this.showCreateNewPassagesDialog = true;
    },
    async saveNewSAT() {
      const res = await SAT.addNewSAT({ ...this.sat, is_new: this.isNew });
      // this.getSATS();
      this.sat_id = res.sat.id;
      this.showCreateSATDialog = false;
    },
    async addQuestionWithId(id) {
      await SAT.addPracticeQuestion(this.practiceId, {
        question_id: id,
        question_type: "Modules\\SAT\\Entities\\SatQuestion"
      });
    },
    async saveAll(type) {
      await this.saveNewPassage();
      if (this.isTeacher || this.isCompany) {
        this.question.source = 3;
      }
      if (this.isEdit) {
        await this.updateQuestion();
      } else {
        await this.saveQuestion();
      }
      if (this.practiceId && this.practiceId > 0) {
        this.$emit("addNewQuestionToPractice", type);
      } else {
        if (type === "back") {
          this.$router.go(-1);
        } else {
          let box = document.getElementsByClassName("right-content")[0];
          box.scrollTo(0, 0);
        }
      }
    },
    async saveSmartQuestions() {
      this.showProgress = true;
      this.progress = 0;
      if (this.smartQuestions && this.smartQuestions.length > 0) {
        for (let i = 0; i <= this.smartQuestions.length; i++) {
          setTimeout(async () => {
            let question = this.smartQuestions[i].question;
            let passage = this.smartQuestions[i].passage;
            if (passage.content && passage.content !== "") {
              const res = await SAT.addPassage({
                ...passage,
                sat_id: this.sat_id,
                sat_subject_id: this.sat_subject_id
              });
              question.sat_passage_id = res.sat_passage.id;
            }
            if (this.isTeacher || this.isCompany) {
              question.source = 3;
            }
            const res = await SAT.addNewQuestion({
              ...question,
              sat_id: this.sat_id,
              is_new: this.isNew,
              sat_subject_id: this.sat_subject_id
            });
            if (this.practiceId && this.practiceId > 0) {
              await this.addQuestionWithId(res.sat_question.id);
            }
            this.progress++;
          }, i * 1000);
        }
      }
    },
    async saveNewPassage() {
      if (this.passage.content && this.passage.content !== "") {
        if (this.question.sat_passage_id && this.question.sat_passage_id > 0) {
          await SAT.updatePassage(this.question.sat_passage_id, {
            ...this.passage,
            title: this.passage.title ? this.passage.title : "",
            introduction: this.passage.introduction
              ? this.passage.introduction
              : []
          });
        } else {
          const res = await SAT.addPassage({
            ...this.passage,
            sat_id: this.sat_id,
            sat_subject_id: this.sat_subject_id
          });
          this.question.sat_passage_id = res.sat_passage.id;
        }
      }
      this.showCreateNewPassagesDialog = false;
    },
    async saveQuestion() {
      const res = await SAT.addNewQuestion({
        ...this.question,
        sat_id: this.sat_id,
        is_new: this.isNew,
        sat_subject_id: this.sat_subject_id
      });
      if (this.practiceId && this.practiceId > 0) {
        await this.addQuestionWithId(res.sat_question.id);
      }
      this.$message({
        message: "Success",
        type: "success"
      });
      this.question = {
        sat_passage_id: null,
        type: "default",
        content: "",
        explanation: "",
        source_slug: "official",
        source: 1,
        difficulty: null,
        sat_question_tag_ids: [],
        correct_count: 0,
        answered_count: 0,
        sat_options: [
          {
            order: 1,
            letter: "A",
            title: ""
          },
          {
            order: 2,
            letter: "B",
            title: ""
          },
          {
            order: 3,
            letter: "C",
            title: ""
          },
          {
            order: 4,
            letter: "D",
            title: ""
          }
        ],
        sat_answers: [
          {
            answers: []
          }
        ]
      };
      this.passage = {
        introduction: [],
        content: "",
        below_content: ""
      };
    },

    smartOptions(str) {
      console.log(str);
      this.scrollToQuestion();
      if (this.question.type === "default") {
        const {
          passage,
          content,
          options,
          explanation,
          answers
        } = this.smartQuestion2(str);
        console.log(content, options, explanation);
        this.passage.content = null;
        this.passage.content = passage;
        if (content) {
          this.question.content = content;
        }
        if (options && options.length > 0) {
          options.forEach((option, index) => {
            this.question.sat_options[index].title = option;
          });
        }
        if (explanation) {
          this.question.explanation = explanation;
        }
        if (answers && answers.length > 0) {
          this.question.sat_answers[0].answers = answers;
        }
      } else {
        const { content, explanation, answers } = this.smartQuestionMath2(str);
        if (content) {
          this.question.content = content;
        }
        if (explanation) {
          this.question.explanation = explanation;
        }
        if (answers && answers.length > 0) {
          this.question.sat_answers = [];
          answers.forEach((answer, index) => {
            this.question.sat_answers.push({
              answers: [answer]
            });
          });
        }
      }
    },
    removeQuestion(index) {
      console.log(index);
      this.smartQuestions.splice(index, 1);
    },
    replaceSmartString(string) {
      let newString = string;
      // newString = this.replaceAll(newString, " style=\"text-align:center;\"", "");
      newString = this.replaceAll(newString, "<br>", "</p><p>");
      return newString;
    },
    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },
    smartQuestionMath(inputStr) {
      const htmlRegex = /<p>(.+?)<\/p>/s;
      const match = inputStr.match(htmlRegex);
      const info = match[1].split("<br>");
      const question = info[0];
      const answersText = info[1];
      const answers = answersText.split(",");
      const explanation = info[2];
      console.log([question, answers, explanation]);
      return {
        content: question,
        explanation: explanation,
        answers: answers
      };
    },
    smartQuestionMath2(inputStr) {
      inputStr = this.replaceAll(inputStr, "&nbsp;", "");
      const info = this.extractParagraphs(inputStr);
      const question = info[0];
      const answersText = this.getHtmlPText(info[1]);
      const answers = answersText.split(",");
      const explanation = info[2];
      console.log([question, answers, explanation]);
      return {
        content: question,
        explanation: explanation,
        answers: answers
      };
    },
    smartQuestion(inputStr) {
      console.log(inputStr);
      inputStr = this.replaceAll(inputStr, "&nbsp;", "");
      const htmlRegex = /<p>(.+?)<\/p>/s;
      const match = inputStr.match(htmlRegex);
      const info = match[1].split("<br>");
      // const question = info[1];
      const choices = [];
      let firstOptionIndex = -1;
      let lastOptionIndex = -1;
      for (let i = 0; i < info.length; i++) {
        if (info[i].match(/[A-Z][.)][^A-Z]+/g)) {
          if (firstOptionIndex === -1) {
            firstOptionIndex = i;
          }
          lastOptionIndex = i;
          let str = info[i]
            .trim()
            .slice(2)
            .trim();
          choices.push(str);
        }
      }
      // console.log(choices, firstOptionIndex);
      console.log(0, firstOptionIndex - 2);
      let question = "";
      let passage = "";
      if (this.sat_subject_id === 4 || this.sat_subject_id === 3) {
        if (firstOptionIndex >= 1) {
          for (let i = 0; i <= firstOptionIndex - 1; i++) {
            question += info[i];
            if (i !== firstOptionIndex - 1) {
              question += "<br>";
            }
          }
        } else {
          question = null;
        }
        console.log(question);
        passage = null;
      } else {
        passage = "";
        if (firstOptionIndex >= 2) {
          for (let i = 0; i <= firstOptionIndex - 2; i++) {
            passage += info[i];
            if (i !== firstOptionIndex - 2) {
              passage += "<br>";
            }
          }
        } else {
          passage = null;
        }
        question = info[firstOptionIndex - 1];
      }
      // const passage = firstOptionIndex >= 2 ? info[firstOptionIndex - 2] : null;
      let answersText = info[lastOptionIndex + 1].trim();
      answersText = this.replaceAll(answersText, "&nbsp;", "");
      answersText = this.replaceAll(answersText, " ", "");
      const answers = answersText.split(",");
      let explanation = "";
      console.log(lastOptionIndex + 2, info.length);
      for (let i = lastOptionIndex + 2; i < info.length; i++) {
        explanation += info[i];
        if (i !== info.length) {
          explanation += "<br>";
        }
      }
      console.log([passage, question, choices, answers, explanation]);
      return {
        passage: passage,
        content: question,
        options: choices,
        explanation: explanation,
        answers: answers
      };
    },
    isOptionFormat(str) {
      var pattern = /^[A-Z]\.$|^[A-Z]\)$/;
      if (pattern.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    smartQuestion2(inputStr) {
      console.log(inputStr);
      inputStr = this.replaceAll(inputStr, "&nbsp;", "");
      const info = this.extractParagraphs(inputStr);
      const choices = [];
      let firstOptionIndex = -1;
      let lastOptionIndex = -1;
      let passageLineIndex = -1;
      console.log(info);
      if (info && info.length > 0) {
       
        for (let i = 0; i < info.length; i++) {
          let check = info[i].substring(0, 6);
          // console.log(check);
          // console.log(check.match(/[A-Z][.)][^A-Z]+/g));
          // console.log(info[i].match(/[A-Z][.)][^A-Z]+/g));
          if (check.match(/[A-Z][.)][^A-Z]+/g)) {
            // console.log(info[i]);
            if (firstOptionIndex === -1) {
              firstOptionIndex = i;
            }
            let str = this.getHtmlPText(info[i]);
            const option = str.trim().slice(0, 2);
            if (this.isOptionFormat(option)) {
              str = str
                .trim()
                .slice(2)
                .trim();
              lastOptionIndex = i;
              choices.push(str);
            }
          }
          if (this.getHtmlPText(info[i]) === "-") {
            passageLineIndex = i;
          }
        }
      }
      let question = "";
      let passage = "";
      console.log(passageLineIndex);
      if (passageLineIndex > -1) {
        passage = "";
        if (passageLineIndex >= 1) {
          for (let i = 0; i <= passageLineIndex - 1; i++) {
            passage += info[i];
          }
        } else {
          passage = null;
        }
        if (firstOptionIndex >= 1) {
          for (let i = passageLineIndex + 1; i <= firstOptionIndex - 1; i++) {
            question += info[i];
          }
        } else {
          question = null;
        }
      } else {
        passage = null;
        if (firstOptionIndex >= 1) {
          for (let i = 0; i <= firstOptionIndex - 1; i++) {
            console.log(info[i]);
            question += info[i];
            // if (i !== firstOptionIndex - 1) {
            //   question += "<br>";
            // }
          }
        } else {
          question = null;
        }
      }
      let answersText = this.getHtmlPText(info[lastOptionIndex + 1].trim());
      const answers = answersText.split(",");
      let explanation = "";

      for (let i = lastOptionIndex + 2; i < info.length; i++) {
        explanation += info[i];
      }

      return {
        passage: passage,
        content: question,
        options: choices,
        explanation: explanation,
        answers: answers
      };
    },
    extractParagraphs(htmlString) {
      const regex = /(<p[^>]*>.*?<\/p>)|(<figure[^>]*>.*?<\/figure>)/g;
      const matches = htmlString.match(regex);
      if (matches) {
        return matches;
      }
      return [];
    },
    getHtmlPText(htmlString) {
      const regex = /<p[^>]*>(.*?)<\/p>/g;
      const matches = htmlString.matchAll(regex);
      let result = "";

      for (const match of matches) {
        result += match[1];
      }

      return result;
    },
    smartOneQuestion(str) {
      // str = this.replaceAll(str, "</p><p>", "<br>");
      console.log(str);
      const question = {
        sat_passage_id: null,
        type: this.smartType,
        content: "",
        explanation: "",
        source: 1,
        sat_question_tag_ids: this.smartTags,
        sat_options: [
          {
            order: 1,
            letter: "A",
            title: ""
          },
          {
            order: 2,
            letter: "B",
            title: ""
          },
          {
            order: 3,
            letter: "C",
            title: ""
          },
          {
            order: 4,
            letter: "D",
            title: ""
          }
        ],
        sat_answers: [
          {
            answers: []
          }
        ]
      };
      const passageText = {
        introduction: [],
        content: "",
        below_content: ""
      };
      if (this.smartType === "default") {
        const {
          passage,
          content,
          options,
          explanation,
          answers
        } = this.smartQuestion2(str);
        passageText.content = passage;
        if (content) {
          question.content = content;
        }
        if (options && options.length > 0) {
          console.log(options);
          options.forEach((option, index) => {
            question.sat_options[index].title = "";
            question.sat_options[index].title = option;
          });
        }
        if (explanation) {
          question.explanation = explanation;
        }
        if (answers && answers.length > 0) {
          question.sat_answers[0].answers = answers;
        }
      } else {
        const { content, explanation, answers } = this.smartQuestionMath2(str);
        if (content) {
          question.content = content;
        }
        if (explanation) {
          question.explanation = explanation;
        }
        if (answers && answers.length > 0) {
          question.sat_answers = [];
          answers.forEach((answer, index) => {
            question.sat_answers.push({
              answers: [answer]
            });
          });
        }
      }
      return {
        question,
        passage: passageText
      };
    },
    smartAllQuestions(inputStr) {
      this.scrollToQuestion();
      inputStr = this.replaceAll(inputStr, "&nbsp;", "");
      this.smartQuestions = [];
      // inputStr = this.replaceAll(inputStr, "</p><p>", "<br>");
      // const regex = /(<p>&nbsp;<\/p><p>&nbsp;<\/p>)|(<figure.*?<\/figure>)/gs;
      // const matches = inputStr.match(regex);
      // const result = matches.map(match => match.trim());
      const result = inputStr.split("<p></p><p></p>");
      console.log(result);
      result.forEach(item => {
        const { question, passage } = this.smartOneQuestion(item);
        this.smartQuestions.push({
          question,
          passage
        });
      });
    },
    async updateQuestion() {
      await SAT.updateNewQuestion(this.questionId, {
        ...this.question,
        is_new: this.isNew,
        sat_passage_id: this.question.sat_passage_id,
        type: this.question.type,
        content: this.question.content,
        explanation: this.question.explanation,
        sat_question_tag_ids: this.question.sat_question_tag_ids
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
      // this.$router.go(0);
    }
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep .el-alert__description {
  font-size: 14px;
}
::v-deep .el-dialog__header {
  background: #efefef;
  padding-bottom: 20px;
}
::v-deep .el-dialog__footer {
  background: #efefef;
  padding-top: 20px;
}
::v-deep .add-passage-dialog .el-dialog__body {
  height: 600px;
  overflow: auto;
}
::v-deep .smartText .ck-content {
  min-height: 400px;
  max-height: 800px;
}
::v-deep .smartEditor .ck-content {
  min-height: 200px;
  /* max-height: 600px; */
}
::v-deep .smartEditor .ck-content p {
  margin: 0;
}
/* ::v-deep .smartEditor .ck-content p {
  margin: 0;
  padding: 1rem 0;
  border-top: 1px solid var(--themeColor);
}
::v-deep .smartEditor .ck-content p:first-child {
  border: none;
} */
::v-deep .smartEditor .ck-content p {
  margin: 0 0.5rem;
}
::v-deep .smartEditor .ck-content p:first-child {
  margin-top: 1rem;
}
</style>
